<template>
  <footer
    class="mt-10 lg:mt-20 bg-white p-5 py-16 xl:px-24 xxl:px-144 2xl:px-0 border-t border-border"
  >
    <div class="flex flex-col lg:flex-row 2xl:max-w-screen-xxl mx-auto">
      <span @click.prevent="goToHome" class="cursor-pointer">
        <img src="/assets/images/LoanSpot-logo.svg" alt="" class="logo" />
      </span>
      <div
        class="grid grid-cols-2 lg:grid-cols-4 mt-20 lg:mt-0 lg:ml-144 gap-12 lg:gap-10 xxl:gap-28"
      >
        <div class="products">
          <p class="products--title text-title">Products</p>
          <a
            href="/consumer"
            class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block"
            >Consumer</a
          >
          <a
            href="/sme"
            class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block"
            >SME</a
          >
        </div>
        <div class="company">
          <p class="products--title text-title">Company</p>
          <!-- <a href="/about-us" class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block">About us</a> -->
          <a
            href="https://www.youtube.com/channel/UCKa64vRWgsmaiTOgO80eFnA"
            class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block"
            >Media</a
          >
          <a
            href="https://loanspot.africa/careers"
            @click.prevent="goToHome('careers/')"
            class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block"
            >Careers</a
          >
          <a
            href="https://loanspot.ng"
            class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block"
            >Blog</a
          >
          <!-- <a href="/customers" class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block">Customers</a> -->
        </div>
        <div class="help">
          <p class="products--title text-title">Help</p>
          <a
            target="_blank"
            href="https://intercom.help/loanspot"
            class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block"
            >Help center</a
          >
          <a
            href="mailto:info@loanspot.ng"
            class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block"
            >Contact us</a
          >
          <a
            target="_blank"
            href="http://twitter.com/loanspotng"
            class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block"
            >Community</a
          >
        </div>
        <div class="legal">
          <p class="products--title text-title">Legal</p>
          <!-- <a href="/privacy" class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block">Privacy policy</a> -->
          <a
            href="https://www.notion.so/Terms-and-Conditions-9440e109ef0148dea6845da3487578e5"
            class="text-sm mt-2 products--text text-sec-text md:mt-4 md:text-md block"
            >Terms & Conditions</a
          >
        </div>
      </div>
    </div>
    <div
      class="md:flex md:items-center md:justify-between lg:mt-16 py-4 border-t border-b border-border mt-10 2xl:max-w-screen-xxl mx-auto"
    >
      <div
        class="flex items-center justify-between pb-4 md:justify-start md:p-0"
      >
        <a href="#" class="">
          <img src="/assets//images/play-icon.svg" alt="" class="" />
        </a>
        <a href="#" class="ml-4">
          <img src="/assets/images/app-store-icon.svg" alt="" class="" />
        </a>
      </div>
      <div
        class="flex items-center justify-center pt-4 border-t border-border md:p-0 md:border-0"
      >
        <a href="https://twitter.com/loanspotng" class="">
          <img src="/assets//images/twitter.svg" alt="" class="" />
        </a>
        <a href="https://web.facebook.com/LoanspotNG" class="ml-4">
          <img src="/assets//images/facebook.svg" alt="" class="" />
        </a>
        <a href="https://www.linkedin.com/company/loanspotng" class="ml-4">
          <img src="/assets//images/linkedin.svg" alt="" class="" />
        </a>
      </div>
    </div>
    <div class="mt-8 2xl:max-w-screen-xxl mx-auto">
      <p class="copyright text-sec-text text-center mx-auto">
        &copy;
        <span>{{ currentYear }}</span> Loanspot Digital Technologies, Ltd. All
        Rights Reserved<br /><br />

        All rights reserved All text, graphics, audio files, code, downloadable
        material, and other works on this web site are the copyrighted works of
        Loanspot Digital Technologies Ltd. All Rights Reserved. Any unauthorized
        redistribution or reproduction of any copyrighted materials on this web
        site is strictly prohibited. Other product and company names are
        trademarks of their respective owners. This web site contains simulated
        images; actual appearance may vary.
      </p>
    </div>
  </footer>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: "Footer",
  data() {
    return {
      currentYear: "",
    };
  },
  mounted() {
    this.getCurrentYear();
  },
  methods: {
    getCurrentYear() {
      const year = new Date().getFullYear();
      this.currentYear = year;
    },
    goToHome() {
      const token = localStorage.getItem("auth-token");

      if (token) {
        Cookies.set('auth-token', token, {path: '/', domian: '.loanspot.africa', sameSite:'lax'})
        // window.location.assign(`http://localhost:5501`);
        window.location.assign(`https://loanspot.africa`);
      } else {
        window.location.assign(`https://loanspot.africa`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.products,
.company,
.help,
.legal {
  .products--title {
    font-family: SohneKraftig, sans-serif;
  }
  .text-sm mt-2 products--text {
    text-decoration: none;
  }
}
.copyright {
  font-size: 0.6rem;
  line-height: 13px;
  max-width: 688px;
}
</style>