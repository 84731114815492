<template>
  <div class="bg-main-bg">
    <Navbar
      :amount="search.amount"
      :income="search.income"
      :loanPurpose="search.loanPurpose"
      :loanType="search.loanType"
      :existingLoan="search.existingLoan"
      @valuechange="onValueChange"
      :loanTypes="loanTypes"
      :loanPurposes="loanPurposes"
      @search="handleSearch"
      @showSearch="showSearch"
    />
    <div v-if="isRequest" class="inline-flex justify-center w-full mt-5 lg:mt-32">
      <svg class="animate-spin" width="32" height="32" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" d="M13.75 7C13.75 10.7279 10.7279 13.75 7 13.75C3.27208 13.75 0.25 10.7279 0.25 7C0.25 3.27208 3.27208 0.25 7 0.25C10.7279 0.25 13.75 3.27208 13.75 7ZM1.6 7C1.6 9.98234 4.01766 12.4 7 12.4C9.98234 12.4 12.4 9.98234 12.4 7C12.4 4.01766 9.98234 1.6 7 1.6C4.01766 1.6 1.6 4.01766 1.6 7Z" fill="#EFEFEF"/>
        <path d="M7 13.075C7 13.4478 6.69704 13.7535 6.32611 13.7163C5.37081 13.6204 4.44421 13.3216 3.60954 12.8367C2.57975 12.2385 1.72655 11.3785 1.13655 10.344C0.546549 9.3095 0.240758 8.1373 0.250213 6.94641C0.259668 5.75552 0.584032 4.58833 1.19038 3.56331C1.79673 2.5383 2.66348 1.69195 3.70264 1.11018C4.74181 0.528418 5.91639 0.231942 7.10717 0.250851C8.29795 0.26976 9.46253 0.60338 10.4827 1.21785C11.3096 1.71589 12.0177 2.38405 12.5617 3.17514C12.773 3.48231 12.6509 3.89503 12.3242 4.07455C11.9975 4.25407 11.5904 4.13163 11.3715 3.82984C10.9474 3.24504 10.4089 2.74935 9.78616 2.37428C8.97003 1.8827 8.03836 1.61581 7.08574 1.60068C6.13311 1.58555 5.19345 1.82273 4.36211 2.28815C3.53078 2.75356 2.83738 3.43064 2.3523 4.25065C1.86723 5.07066 1.60773 6.00442 1.60017 6.95713C1.59261 7.90984 1.83724 8.8476 2.30924 9.67521C2.78124 10.5028 3.4638 11.1908 4.28763 11.6694C4.91623 12.0345 5.61 12.2678 6.32674 12.3579C6.69663 12.4043 7 12.7022 7 13.075Z" fill="#1656C1"/>
      </svg>
    </div>
    <div
      class="grid gap-6 lg:mt-32 max-w-6xl mx-auto px-4 xl:px-0 mt-8"
    >
      <div class="flex justify-between items-center">
        <p class="text-lg font-bold text-primary">
          {{ lendersMeta.totalItems || 0 }} lender{{
            lendersMeta.totalItems > 1 ? "s" : ""
          }}
          available
        </p>
        <div class="flex items-center flex-row-reverse">
          <a
            v-if="lenders.length > 0 || !dontApply"
            @click.prevent="applyForLoan"
            class="px-6 py-3 text-sm font-medium rounded-lg hidden md:flex bg-primary items-center justify-center text-white cursor-pointer font-secondary"
            >Apply</a
          >
          <a
            v-if="applications.length > 0"
            @click.prevent="applications = []"
            class="text-sm md:text-sm md:mr-4 text-red-700 font-simple h-fit-content cursor-pointer"
            >De-select all ({{ applications.length }})</a
          >
        </div>
      </div>
      <LoanResult
        v-for="(lender, i) in lenders"
        :key="i"
        :index="i"
        :resultTitle="lender.name"
        :resultSubTitle="lender.loanType.name"
        resultTag="Fastest"
        :interestValue="
          lender.minInterestRate === lender.maxInterestRate
            ? `${lender.maxInterestRate}%`
            : `${lender.minInterestRate}% - ${lender.maxInterestRate}%`
        "
        :approvalValue="`${lender.approvalInHoursText || 'Not specified'}`"
        :durationValue="lender.repaymentDuration"
        :ratingValue="parseFloat(lender.rating).toFixed(0)"
        :rangeValue="`${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'NGN',
        }).format(lender.minAmount)} - ${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'NGN',
        }).format(lender.maxAmount)}`"
        :applicationDetails="lender.applicationDetails"
        :lenderData="lender"
        @lenderselected="selectLender"
        :selected="applications.includes(lender.id)"
        :id="lender.id"
      >
        <img
          slot="resultImage"
          :src="lender.logo"
          alt=""
          class="w-12 h-12 xxl:w-14 xxl:h-14 object-contain rounded"
        />
        <div ref="compulsory" class="flex flex-col" slot="compulsory">
          <span
            class="text-xs"
            v-for="detail in (lender.applicationDetails || []).filter(
              (d) => d.scope === 'primary'
            )"
            :key="detail.id"
            >{{ detail.name }}</span
          >
        </div>
        <div ref="good" class="flex flex-col" slot="good">
          <span
            class="text-xs"
            v-for="detail in (lender.applicationDetails || []).filter(
              (d) => d.scope === 'secondary'
            )"
            :key="detail.id"
            >{{ detail.name }}</span
          >
        </div>
      </LoanResult>
      <div
        class="text-center"
        v-if="lendersLinks.next !== '' && (lendersMeta.totalItems || 0) > 0"
      >
        <button
          @click.prevent="loadMore"
          class="w-full border border-border no-focus py-3 px-6 bg-primary mt-6 rounded-lg w-fit-content font-medium text-sm text-white"
        >
          Load more
        </button>
      </div>
    </div>

    <a
      @click.prevent="applyForLoan"
      :class="[
        isSearching || applications.length === 0 || dontApply ? 'hidden' : '',
      ]"
      class="fixed bottom-0 w-full h-16 flex items-center justify-center bg-primary text-white cursor-pointer md:hidden"
      >Apply
      {{ applications.length > 1 ? `to all (${applications.length})` : "" }}</a
    >
    <Footer />

    <response-modal
      id="response"
      v-show="showApplyModal"
      :showModal="showApplyModal"
      :wrapperClass="['fixed h-screen z-50 top-0 left-0 right-0 bg-sec-bg']"
      modalWrapperClass="md:max-w-md max-w-sm px-4 overflow-y-scroll rounded-lg"
      modalClass="modal-wrap mx-auto bg-white rounded-lg flex flex-col items-center justify-center border border-border mx-auto w-full p-6 overflow-y-scroll"
      contentClass="flex flex-col items-center justify-center w-full overflow-y-scroll"
      titleClass=""
      textClass=""
    >
      <template v-if="isLoanApplicationSuccess">
        <div slot="alt-content" class="w-full">
          <div class="flex items-center flex-col">
            <div
              class="h-24 w-24 rounded-full flex flex-col items-center bg-accent"
            >
              <svg
                class="h-24"
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="40" cy="40" r="40" fill="#1656C1" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.5565 38.7144C21.7104 39.4244 21.6 40.6859 22.31 41.5321L32.9242 54.1816C33.3458 54.684 33.9618 54.9271 34.5698 54.893C34.5781 54.8925 34.5864 54.892 34.5946 54.8914C35.0028 54.8638 35.4062 54.7112 35.7436 54.4281L57.8214 35.9026C58.6675 35.1926 58.7779 33.9311 58.0679 33.0849L56.8718 31.6595C56.1618 30.8133 54.9003 30.7029 54.0541 31.413L34.6335 47.7088L26.5003 38.016C25.7903 37.1698 24.5288 37.0595 23.6827 37.7695L22.5565 38.7144Z"
                  fill="white"
                />
              </svg>
            </div>
            <h5
              class="text-center text-title text-lg md:text-2xl mt-4 font-extralight font-secondary"
            >
              Application Successful
            </h5>
            <h5
              class="text-sm text-text text-center font-simple text-gray-500 mt-2 max-w-xs"
            >
              Invite your friends to get additional visibility for your loans.
            </h5>
          </div>
        </div>
        <button
          slot="cta"
          class="mt-6 py-3 bg-primary text-white w-full md:text-md text-sm rounded-lg no-focus font-extralight font-secondary tracking-wide"
          @click.prevent="continueApplications"
        >
          Continue
        </button>

        <button
          slot="cta"
          class="mt-4 py-3 border border-border w-full text-sm rounded-lg no-focus text-primary bg-accent font-secondary font-normal"
          @click.prevent="endApplications"
        >
          Finish
        </button>
      </template>
    </response-modal>

    <response-modal
      id="response"
      v-show="showAuthModal"
      :wrapperClass="['fixed h-screen z-50 top-0 left-0 right-0 bg-sec-bg']"
      modalClass="modal-wrap mx-auto bg-white rounded-lg flex flex-col items-center justify-center max-w-xs border border-border mx-auto w-full p-6"
      contentClass="flex flex-col items-center justify-center w-full"
    >
      <div slot="alt-content" class="w-full">
        <div class="flex items-center flex-col">
          <h5
            class="text-center text-title text-2xl md:text-2xl mt-4 font-extralight font-secondary"
          >
            Almost there.
          </h5>
          <h5
            class="text-sm text-text text-center font-simple text-gray-500 mt-2"
            style="max-width: 204px"
          >
            Complete your application by logging in or creating an account
          </h5>
        </div>
      </div>
      <button
        slot="cta"
        class="mt-6 py-3 bg-primary text-white w-full md:text-md text-sm rounded-lg no-focus font-extralight font-secondary tracking-wide"
        @click.prevent="() => goToAuth('/login', 'Clicked Login on Continue Apply')"
      >
        Login
      </button>
      <button
        slot="cta"
        class="mt-4 py-3 border border-border w-full text-sm rounded-lg no-focus text-primary bg-accent font-secondary font-normal"
        @click.prevent="() => goToAuth('/signup', 'Clicked Signup on Continue Apply')"
      >
        Create an account
      </button>
    </response-modal>
  </div>
</template>

<script>
import Navbar from "../components/search/navbar";
import LoanResult from "../components/search/loan-result";
import Footer from "../components/shared/footer";
import { BASE_API } from "../constants";
import { ApiInstance } from "../utils";
import ResponseModal from "../components/partials/response-modal";
import { mapState } from "vuex";
import mixpanel from 'mixpanel-browser';
import Cookies from 'js-cookie';

export default {
  name: "LoanSearch",
  components: {
    Navbar,
    LoanResult,
    Footer,
    ResponseModal,
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
    dontApply() {
      // if (+this.search.amount < 1000) {
      //   return true;
      // }
      return false;
    },
  },
  data() {
    return {
      responseSuccess: {
        title: `You're all set!`,
        text: `Thanks for taking time out to finish the test.`,
      },
      selectedLoans: [],
      selectedValue: false,
      lendersUrl: null,
      lendersLimit: 10,
      lenders: [],
      loanTypes: [],
      lendersMeta: {},
      lendersLinks: {},
      rangeValue: [],
      page: 1,
      search: {
        amount: null,
        income: null,
        loanType: null,
        existingLoan: null,
        loanPurpose: null,
      },
      loanPurposes: [],
      isSearching: false,
      isRequest: false,
      applications: [],
      showAuthModal: false,
      applicationRequirements: {
        financialStatement: false,
        proofBusinessOwnership: false,
        utilityBill: false,
        // workEmail: false,
        // workId: false,
        // governmentId: false,
        assetCollateral: false,
      },
      showApplyModal: false,
      isLoanApplicationSuccess: false,
      initialApply: false,
      mixDId: Cookies.get('mix_id')
    };
  },
  watch: {
    $route: async function (newValue) {
      const currentUrl =
        "/lenders/search?" + new URLSearchParams(newValue.query).toString();
      await this.searchLenders(currentUrl);
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch("userProfile");
    } catch {
      console.log("⛔️");
    }

    await this.getLoanTypes();
    await this.getLoanPurposes();
    
    // if(Object.entries(this.$route.query).length === 0 && this.$route.query.constructor === Object) {
    //   if(this.profile?.user?.id) {
    //     return this.$router.replace('/');
    //   } else {
    //     return this.$router.replace('/login');
    //   }
    // } else {
      mixpanel.track('Visited Search', {
        'distinct_id': this.mixDId
      });
    // }

    if (this.profile.user?.id) {
      const initialApply = localStorage.getItem("initialApply");
      if (initialApply) {
        const applied = JSON.parse(localStorage.getItem("applied"));
        this.isRequest = true;
        const response = await ApiInstance.post("/loans", applied);

        this.isRequest = false;
        if (response.data?.statusCode === 201) {
          let getLoanType, getLoanPurpose, getLenders = [];
          this.loanTypes.filter(type => {
            if(type.id === this.$route.query.loanType) {
              getLoanType = type.name
            }
          });
          this.loanPurposes.filter(purpose => {
            if(purpose.id === this.$route.query.loanPurpose) {
              getLoanPurpose = purpose.title
            }
          });
          this.applications.forEach(i => {
            for (let lender = 0; lender < this.lenders.length; lender++) {
              if(this.lenders[lender].id === i) {
                getLenders.push(this.lenders[lender].name)
              }
            }
          })
          mixpanel.track('Applied successfully', {
            'distinct_id': this.mixDId,
            'lenders': getLenders,
            'amount': new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(this.$route.query.amount),
            'income': new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(this.$route.query.income),
            'loanType': getLoanType,
            'existingLoan': this.$route.query.existingLoan,
            'loanPurpose': getLoanPurpose,
          })
          this.showApplyModal = true;
          this.isLoanApplicationSuccess = true;
          this.initialApply = false;
          localStorage.removeItem("initialApply");
          localStorage.removeItem("applied");
        }
      }
    } else {
      console.log("⛔️");
    }

    this.search.amount = this.$route.query.amount;
    this.search.loanType = this.$route.query.loanType;
    this.search.loanPurpose = this.$route.query.loanPurpose;
    this.search.existingLoan = this.$route.query.existingLoan;
    this.search.income = this.$route.query.income;
    this.search.incomeCurrency = this.$route.query.incomeCurrency;
    this.search.loanCurrency = this.$route.query.loanCurrency;

    if (this.search.amount) {
      const currentUrl =
        "/lenders/search?" + new URLSearchParams(this.$route.query).toString();
      this.searchLenders(currentUrl);
    } else await this.getLenders();
  },
  methods: {
    async finishApplication() {
      // mixpanel.track('Clicked Continue application', {
      //   'distinct_id': this.mixDId
      // })
      this.isRequest = true;
        const response = await ApiInstance.post("/loans", {
          lenders: this.applications,
          ...this.search,
          ...this.applicationRequirements,
          income: this.search.income === "" ? null : this.search.income,
        });

        this.isRequest = false;
        if (response.data?.statusCode === 201) {
          this.showApplyModal = true;
          let getLoanType, getLoanPurpose, getLenders = [];
          this.loanTypes.filter(type => {
            if(type.id === this.$route.query.loanType) {
              getLoanType = type.name
            }
          });
          this.loanPurposes.filter(purpose => {
            if(purpose.id === this.$route.query.loanPurpose) {
              getLoanPurpose = purpose.title
            }
          });
          this.applications.forEach(i => {
            for (let lender = 0; lender < this.lenders.length; lender++) {
              if(this.lenders[lender].id === i) {
                getLenders.push(this.lenders[lender].name)
              }
            }
          })
          mixpanel.track('Applied successfully', {
            'distinct_id': this.mixDId,
            'lenders': getLenders,
            'amount': new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(this.$route.query.amount),
            'income': new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(this.$route.query.income),
            'loanType': getLoanType,
            'existingLoan': this.$route.query.existingLoan,
            'loanPurpose': getLoanPurpose,
          })
          return (this.isLoanApplicationSuccess = true);
        }

      this.showApplyModal = false;
      this.$notie.alert({
        text: "Your loan application was not successful.",
        type: "error",
        time: 3,
      });
    },
    goToAuth(path, mixAction) {
      mixpanel.track(mixAction, {
        'distinct_id': this.mixDId
      })
      this.$router.push(path);
    },
    cancelApplication() {
      this.showApplyModal = false;
      mixpanel.track('Canceled application', {
        'distinct_id': this.mixDId
      })
    },
    continueApplications() {
      this.applications = [];
      this.showApplyModal = false;
      this.isLoanApplicationSuccess = false;
    },
    endApplications() {
      this.$router.replace({path: '/'})
    },
    applyForLoan() {
      if (this.applications.length < 1) {
        return this.$notie.alert({
          text: "Select at least one lender to continue",
          type: "error",
          time: 3,
        });
      }

      if (this.applications.length > 0) {
        if (this.profile.user?.id) {
          this.finishApplication();
        } else {
          this.showApplyModal = true;
          this.initialApply = true;
          const applied = {
            lenders: this.applications,
            ...this.search,
            ...this.applicationRequirements,
            income: this.search.income === "" ? null : this.search.income,
          };
          this.showAuthModal = true;
          localStorage.setItem("initialApply", this.initialApply);
          localStorage.setItem("applied", JSON.stringify(applied));
        }

        mixpanel.track(
          'Clicked Apply', {
          'distinct_id': this.mixDId
        });
      }
    },
    selectLender(val) {
      const valI = this.applications.indexOf(val);
      if (valI < 0) {
        this.applications.push(val);
        if (this.applications.length === 4) {
          this.applications.splice(valI, 1);
          return this.$notie.alert({
            text: "A maximum of 3 lenders can be selected per application.",
            type: "error",
            time: 5,
          });

        }
      } else {
         
        this.applications.splice(valI, 1);
      } 
    },
    showSearch(val) {
      this.isSearching = val;
    },
    onValueChange({ key, value }) {
      this.search[key] = value;
    },
    async handleSearch() {
      const important = {
        amount: this.search.amount,
        income: this.search.income,
        loanType: this.search.loanType,
        existingLoan: this.search.existingLoan,
        loanPurpose: this.search.loanPurpose,
      };

      const unFilled = Object.values(important).filter(
        (s) => s === null || s === undefined
      ).length;
      if (important.amount === "0") {
        return this.$notie.alert({
          text: `Amount cannot be '0'.`,
          type: "error",
          time: 4,
          position: "top",
        });
      }
      if (important.income === "0") {
        return this.$notie.alert({
          text: `Income cannot be '0'.`,
          type: "error",
          time: 4,
          position: "top",
        });
      }
      if (unFilled > 0) {
        return this.$notie.alert({
          // text: `Fill in the remaining search terms.`,
          text: `Fill in the remaining search terms (${unFilled} left).`,
          type: "error",
          time: 3,
          position: "top",
        });
      }
      if (this.search.amount === 0) {
        this.$notie.alert({
          text: `Amount cannot be 0`,
          type: "error",
          time: 3,
          position: "top",
        });
        return;
      }
      const query = {
        ...this.search,
        page: this.page,
        limit: this.lendersLimit,
      };

      if (
        "/search?" + new URLSearchParams(query).toString() ===
        this.$route.fullPath
      ) {
        return;
      }

      this.$router.replace({
        path: "/search",
        query,
      });
    },
    async loadMore() {
      await this.getLenders(this.lendersLinks?.next);
    },
    async searchLenders(url = undefined) {
      this.isRequest = true;
      if (!url) {
        return;
      }
      const response = await ApiInstance.get(url);
      if (response.data?.statusCode === 200) {
        this.lenders = response.data.data.items;
        this.lendersMeta = response.data.data.meta;
        this.lendersLinks = response.data.data.links;
      }
      this.isRequest = false;
    },
    async getLenders(url = undefined) {
      if (!url) {
        url =
          BASE_API +
          "/lenders?page=" +
          this.page +
          "&limit=" +
          this.lendersLimit;
      }
      this.isRequest = true;
      try {
        const response = await ApiInstance.get(url);
        this.lenders = [...this.lenders, ...response?.data?.data?.items];
        this.lendersMeta = response?.data?.data?.meta;
        this.lendersLinks = response?.data?.data?.links;
        this.isRequest = false;
      } catch (error) {
        this.isRequest = false;
        console.log(error);
      }
    },
    async getLoanTypes() {
      try {
        const response = await ApiInstance.get("/loan-types");
        this.loanTypes = response?.data?.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getLoanPurposes() {
      try {
        const response = await ApiInstance.get("/loan-purposes");
        this.loanPurposes = response?.data?.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  head: {
    title: function () {
      return {
        inner: "Loanspot",
        complement: "Search for lenders",
      };
    },
    meta: [
      { name: "description", content: "Loanspot search page" },
      { name: "application-name", content: "Loanspot" },
    ],
  },
};
</script>

<style lang="scss" scoped>
.apply-btn {
  width: 180px;
}
</style>
