<template>
  <div>
    <div
      :class="slideIn"
      class="p-8 xl:px-24 fixed top-0 left-0 bg-white border-r border-border w-80 h-screen"
    >
      <div class="flex items-center justify-between">
        <a href="/" class="cursor-pointer">
          <img src="/assets/images/LoanSpot-logo.svg" alt="" class="" />
        </a>
        <span @click="closeMobileMenu()">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="2.92871"
              y="0.100525"
              width="24"
              height="3"
              rx="1"
              transform="rotate(45 2.92871 0.100525)"
              fill="#1656C1"
            />
            <rect
              x="19.1924"
              y="2.2218"
              width="24"
              height="3"
              rx="1"
              transform="rotate(135 19.1924 2.2218)"
              fill="#1656C1"
            />
          </svg>
        </span>
      </div>
      <div class="flex flex-col auth-wrap">
        <div class="flex items-center">
          <span class="pr-4 hidden">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0002 0.833313C15.0628 0.833313 19.1668 4.93737 19.1668 9.99998C19.1668 15.0626 15.0628 19.1666 10.0002 19.1666C4.93755 19.1666 0.833496 15.0626 0.833496 9.99998C0.833496 4.93737 4.93755 0.833313 10.0002 0.833313ZM2.55489 9.08949C2.79784 9.40044 3.47336 9.7983 4.50705 10.1229C4.91406 10.2507 5.35983 10.3631 5.83812 10.4585C5.83505 10.3065 5.8335 10.1537 5.8335 9.99998C5.8335 7.22694 6.33813 4.72273 7.21229 3.03523C4.72027 4.03367 2.88873 6.33082 2.55489 9.08949ZM5.94111 12.1757C4.60295 11.9431 3.45555 11.5911 2.58599 11.1381C2.99049 13.7951 4.78915 15.9939 7.21228 16.9647C6.57405 15.7326 6.1328 14.0652 5.94111 12.1757ZM7.64927 12.3978C8.39685 12.4651 9.18424 12.5 10.0002 12.5C10.8029 12.5 11.5939 12.4545 12.3546 12.3701C11.9787 15.3681 10.9393 17.5 10.0002 17.5C9.06391 17.5 8.02807 15.3812 7.64927 12.3978ZM12.4888 10.6704C11.6975 10.7729 10.8569 10.8333 10.0002 10.8333C9.12998 10.8333 8.29468 10.7902 7.51294 10.7085C7.50453 10.4754 7.50018 10.2391 7.50018 9.99997C7.50018 5.81997 8.82818 2.49997 10.0002 2.49997C11.1722 2.49997 12.5002 5.81997 12.5002 9.99997C12.5002 10.2261 12.4963 10.4497 12.4888 10.6704ZM14.0662 12.1057C13.8784 14.0241 13.4341 15.7175 12.788 16.9647C15.254 15.9768 17.0732 13.7171 17.4345 10.997C16.551 11.4565 15.3874 11.8408 14.0662 12.1057ZM17.4406 9.05008C17.0485 9.49024 15.8116 10.0131 14.1637 10.3784C14.1658 10.2529 14.1668 10.1267 14.1668 9.99998C14.1668 7.22694 13.6622 4.72271 12.788 3.03522C15.2682 4.02888 17.0941 6.30893 17.4406 9.05008Z"
                fill="#646F79"
              />
            </svg>
          </span>
        </div>
        <div class="mt-8">
          <div v-if="profile.user && profile.loaded" class="">
            <!-- <div class="pb-3 mt-3 border-b border-border">
              <a
                href="/search"
                class="font-bold bg-accent-2 h-10 rounded text-primary flex items-center justify-center"
              >
                Search for loans
              </a>
            </div> -->
            <a :href="dashboard.overview" class="text-primary py-4 block"
              >Overview</a
            >
            <a :href="dashboard.history" class="text-primary py-4 block"
              >History</a
            >
            <a :href="dashboard.settings" class="text-primary py-4 block"
              >Settings</a
            >
            <a :href="dashboard.help" class="text-primary py-4 block">Help</a>
            <span @click.prevent="logoutUser" class="text-primary py-4 block"
              >Logout</span
            >
          </div>
          <div v-else class="auth-wrap mt-16">
            <a
              :href="this.auth.signup"
              class="bg-primary rounded flex items-center justify-center h-12 text-white"
              >Sign up now</a
            >
            <a
              :href="this.auth.login"
              class="text-primary h-12 rounded flex items-center justify-center mt-8 border border-border"
              >Log in</a
            >
          </div>
        </div>
      </div>
    </div>
    <nav
      :class="
        fullSearch ? 'fixed w-full top-0 border-0' : 'border-b border-border'
      "
      class="bg-white px-4 py-5 md:p-8 lg:p-0"
    >
      <div class="flex items-center justify-between w-full text-xl lg:hidden">
        <a href="/" class="cursor-pointer">
          <img src="/assets/images/LoanSpot-logo.svg" alt="" class="" />
        </a>
        <div class="flex items-center">
          <div
            v-if="profile.user && profile.loaded"
            class="flex items-center justify-center text-white rounded w-12 h-12 bg-primary ml-4 font-family-bold"
          >
            {{ profile.user.firstName[0] }}{{ profile.user.lastName[0] }}
          </div>
          <span @click="openMobileMenu()" :class="hideHamburger" class="ml-2">
            <svg
              width="24"
              height="17"
              viewBox="0 0 24 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="3" rx="1" fill="#1656C1" />
              <rect y="7" width="24" height="3" rx="1" fill="#1656C1" />
              <rect y="14" width="24" height="3" rx="1" fill="#1656C1" />
            </svg>
          </span>
        </div>
      </div>
      <div
        @click.prevent="closeSearch"
        class="backdrop left-0 top-0"
        :class="fullSearch ? 'fixed' : ''"
      ></div>
      <div
        ref="navWrap"
        id="navWrap"
        class="fixed top-0 left-0 right-0 w-full bg-white border-b border-border"
      >
        <div
          class="px-8 py-6 xl:px-24 bg-white lg:items-center lg:justify-between hidden lg:flex lg:flex-row flex-col xxl:max-w-screen-xxl xxl:m-auto xxl:px-144 2xl:px-0"
        >
          <div class="logo-wrap flex items-center text-xl">
            <a href="/" class="cursor-pointer">
              <img src="/assets/images/LoanSpot-logo.svg" alt="" class="" />
            </a>
            <div
              @click="showSearch"
              :class="fullSearch ? 'hidden' : ''"
              class="search-hold-wrap h-10 flex ml-10"
            >
              <div
                class="bg-input-bg w-full border-t border-b border-l border-input-border rounded-l-lg flex px-4 py-2"
              >
                <input
                  type="text"
                  class="w-28 text-lg bg-transparent focus:outline-none outline-none"
                  :value="initialSearch"
                />
              </div>
              <button
                class="bg-primary search-btn-small rounded-r-lg flex items-center justify-items-center p-5 focus:outline-none"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 12L16.5 16.5"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    r="6"
                    transform="matrix(-1 0 0 1 7 7)"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div v-if="profile.user && profile.loaded" class="flex items-center">
            <router-link :to="dashboard.overview">
              <span
                class="text-primary text-sm ml-8 block hover:underline cursor-pointer"
                >Overview</span
              >
            </router-link>
            <router-link :to="dashboard.history">
              <span
                class="text-primary text-sm ml-8 block hover:underline cursor-pointer"
                >History</span
              >
            </router-link>
            <router-link :to="dashboard.settings">
              <span
                class="text-primary text-sm ml-8 block hover:underline cursor-pointer"
                >Settings</span
              >
            </router-link>
            <div
              @click="logout = !logout"
              class="flex items-center justify-between cursor-pointer"
              v-if="profile.user && profile.loaded"
            >
              <div class="flex items-center cursor-pointer">
                <div
                  class="flex items-center justify-center text-white rounded w-12 h-12 bg-primary ml-4 font-family-bold"
                >
                  {{ profile.user.firstName[0] }}{{ profile.user.lastName[0] }}
                </div>
              </div>
              <div class="ml-4">
                <svg
                  width="18"
                  height="11"
                  viewBox="0 0 18 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.00009 7.65704L1.92898 0.585938L0.514771 2.00015L7.58588 9.07126L7.58584 9.07129L9.00006 10.4855L17.4853 2.00022L16.0711 0.586008L9.00009 7.65704Z"
                    fill="#323643"
                  />
                </svg>
              </div>
              <div
                :class="logout ? '' : 'hidden'"
                class="absolute top-24 rounded bg-white border border-input "
              >
                <!-- <div class="pb-3 mt-3 mx-3 border-b border-border">
                  <a
                    href="/search"
                    class="font-bold bg-accent-2 text-sm h-8 rounded text-primary flex items-center justify-center"
                  >
                    Search for loans
                  </a>
                </div> -->
                <!-- <a
                  :href="dashboard.overview"
                  class="text-primary py-2 text-sm px-3 block hover:bg-accent-2"
                  >Overview</a
                >
                <a
                  :href="dashboard.history"
                  class="text-primary py-2 text-sm px-3 block hover:bg-accent-2"
                  >History</a
                >
                <a
                  :href="dashboard.settings"
                  class="text-primary py-2 text-sm px-3 block hover:bg-accent-2"
                  >Settings</a
                > -->
                <a
                  :href="dashboard.help"
                  class="text-primary py-2 text-sm pl-3 pr-20 block hover:bg-accent-2"
                  >Help</a
                >
                <span
                  @click.prevent="logoutUser"
                  class="text-primary py-2 text-sm pl-3 pr-20 block hover:bg-accent-2"
                  >Logout</span
                >
              </div>
            </div>
          </div>
            <div
              v-else-if="!profile.user && profile.loaded"
              class="flex items-center auth-wrap"
            >
              <div class="flex items-center">
                <a
                  :href="this.auth.login"
                  class="lg:pl-4 text-login mt-8 lg:mt-0 h-12 flex items-center"
                  >Log in</a
                >
              </div>
              <a
                :href="this.auth.signup"
                class="w-32 bg-primary rounded flex items-center justify-center lg:ml-6 mt-8 lg:mt-0 h-12 text-white"
                >Sign up</a
              >
            </div>
        </div>

        <div
          :class="fullSearch ? '' : 'hidden'"
          ref="fullSearchWrap"
          class="w-full"
        >
          <div class="lg:bg-white">
            <div
              ref="fullSearchInner"
              class="full-search-wrap bg-white xs:mx-8 md:rounded-none md:m-0 p-4 md:pb-10 lg:px-10x1 md:px-8 xxl:px-144 2xl:max-w-screen-xxl 2xl:px-0 2xl:mx-auto border-b border-border"
            >
              <form action="" class="grid md:grid-cols-3 xl:grid-cols-6 gap-3">
                <div
                  class="w-full flex flex-col items-start justify-center p-2 text-xs text-primary md:text-title bg-transparent border border-border rounded"
                >
                  <label class="w-full" for="borrow-amount-input"
                    >How much do you want to borrow?</label
                  >

                  <div class="w-full flex items-center mt-1 relative">
                    <span class="text-xl mr-2 borrow-amount-input absolute"
                      >₦</span
                    >
                    <input
                      autofocus
                      class="w-full -ml-1 text-xl focus:outline-none outline-none bg-transparent text-primary md:text-title borrow-amount-input pl-6"
                      id="borrow-amount-input"
                      type="number"
                      name="amount"
                      maxlength="10"
                      autocomplete="off"
                      :value="amount"
                      @blur.prevent="
                        (e) =>
                          $emit('valuechange', {
                            key: 'amount',
                            value: e.target.value,
                          })
                      "
                    />
                  </div>
                </div>
                <div
                  class="w-full flex flex-col items-start justify-center p-2 text-xs text-primary md:text-title bg-transparent border border-border rounded"
                >
                  <label class="w-full" for="borrow-amount-input"
                    >What is your net monthly income?</label
                  >

                  <div class="w-full flex items-center mt-1 relative">
                    <span class="text-xl mr-2 borrow-amount-input absolute"
                      >₦</span
                    >
                    <input
                      autofocus
                      class="w-full -ml-1 text-xl focus:outline-none outline-none bg-transparent text-primary md:text-title borrow-amount-input pl-6"
                      id="borrow-amount-income"
                      type="number"
                      name="income"
                      maxlength="10"
                      autocomplete="off"
                      :value="income"
                      @blur.prevent="
                        (e) =>
                          $emit('valuechange', {
                            key: 'income',
                            value: e.target.value,
                          })
                      "
                    />
                  </div>
                </div>
                <div
                  class="w-full flex flex-col items-start justify-center p-2 text-xs text-primary md:text-title bg-transparent border border-border rounded"
                  for="borrow-amount-input"
                >
                  <label class="w-full" for="borrow-amount-input">
                    Type of loan
                    <select
                      class="w-full text-lg focus:outline-none outline-none bg-transparent text-primary md:text-title borrow-amount-input md:mt-1"
                      @change.prevent="
                        (e) =>
                          $emit('valuechange', {
                            key: 'loanType',
                            value: e.target.value,
                          })
                      "
                    >
                      <option :value="null">Choose</option>
                      <option
                        v-for="type in loanTypes"
                        :key="type.id"
                        :value="type.id"
                        :selected="loanType === type.id"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                  </label>
                </div>
                <label
                  class="w-full flex flex-col items-start justify-center py-2 px-3 lg:px-3 text-xs text-primary md:text-title bg-transparent border border-border rounded"
                  for="borrow-amount-input"
                >
                  What is the purpose of this loan?
                  <select
                    class="w-full mt-1 -ml-1 text-lg focus:outline-none outline-none bg-transparent text-primary md:text-title borrow-amount-input"
                    @change.prevent="
                      (e) =>
                        $emit('valuechange', {
                          key: 'loanPurpose',
                          value: e.target.value,
                        })
                    "
                  >
                    <option :value="null">Choose</option>
                    <option
                      v-for="(purpose, i) in loanPurposes.filter(
                        (p) => p.loanType.id === loanType
                      )"
                      :key="i"
                      :value="purpose.id"
                      :selected="purpose.id === loanPurpose"
                    >
                      {{ purpose.title }}
                    </option>
                  </select>
                </label>
                <label
                  class="w-full flex flex-col items-start justify-center py-2 px-3 lg:px-3 text-xs text-primary md:text-title bg-transparent border border-border rounded"
                  for="borrow-amount-input"
                >
                  Existing loan?
                  <select
                    class="w-full mt-1 -ml-1 text-lg focus:outline-none outline-none bg-transparent text-primary md:text-title borrow-amount-input"
                    @change.prevent="
                      (e) =>
                        $emit('valuechange', {
                          key: 'existingLoan',
                          value: e.target.value,
                        })
                    "
                  >
                    <option :value="null">Choose</option>
                    <option value="no" :selected="existingLoan === 'no'">
                      No
                    </option>
                    <option value="yes" :selected="existingLoan === 'yes'">
                      Yes
                    </option>
                  </select>
                </label>
                <button
                  @click.prevent="search"
                  class="w-full px-5 self-end bg-primary rounded text-white focus:outline-none outline-none h-12 md:h-full"
                >
                  Search
                </button>
              </form>
              <div class="w-full mt-8">
                <svg
                  @click.prevent="closeSearch"
                  class="mx-auto cursor-pointer"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="24" cy="24" r="24" fill="#F9FAFC" />
                  <path
                    d="M23.9997 22.5867L28.9497 17.6367L30.3637 19.0507L25.4137 24.0007L30.3637 28.9507L28.9497 30.3647L23.9997 25.4147L19.0497 30.3647L17.6357 28.9507L22.5857 24.0007L17.6357 19.0507L19.0497 17.6367L23.9997 22.5867Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div
      ref="searchTriggerMobile"
      class="search-hold-wrap-mobile flex justify-center items-center pt-6 px-4 xl:px-0 lg:hidden"
    >
      <div
        @click="showSearch"
        :class="fullSearch ? 'hidden' : ''"
        class="flex border border-border bg-white w-full rounded-lg px-2 text-primary"
      >
        <div class="w-full rounded-l-lg flex py-2">
          <input
            type="text"
            class="w-full text-lg bg-transparent focus:outline-none outline-none"
            :value="initialSearch"
          />
        </div>
        <button
          class="flex items-center justify-items-center focus:outline-none"
        >
          <svg
            width="28"
            height="21"
            viewBox="0 0 28 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="h-3"
          >
            <rect y="2" width="28" height="3" rx="1" fill="#1656C1" />
            <rect
              x="8"
              width="7"
              height="3"
              rx="1"
              transform="rotate(90 8 0)"
              fill="#1656C1"
            />
            <rect
              x="24"
              y="7"
              width="7"
              height="3"
              rx="1"
              transform="rotate(90 24 7)"
              fill="#1656C1"
            />
            <rect
              x="16"
              y="14"
              width="7"
              height="3"
              rx="1"
              transform="rotate(90 16 14)"
              fill="#1656C1"
            />
            <rect y="9" width="28" height="3" rx="1" fill="#1656C1" />
            <rect y="16" width="28" height="3" rx="1" fill="#1656C1" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { AUTH_ROUTES, DASHBOARD_ROUTES } from "../../constants";

export default {
  name: "Navbar",
  props: {
    amountToBorrow: [String || Number],
    loanPurposes: Array,
    loanTypes: Array,
    amount: String,
    income: String,
    loanType: String,
    loanPurpose: String,
    existingLoan: String,
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      fullSearch: false,
      fullSearchMobile: false,
      auth: {
        signup: AUTH_ROUTES.SIGNUP,
        login: AUTH_ROUTES.LOGIN,
      },
      slideIn: "hidden",
      hideHamburger: "",
      initialSearch: "",
      params: [],
      checkParams: [],
      paramsAmount: "",
      paramsIncome: "",
      paramsType: "",
      logout: false,
      dashboard: {
        overview: DASHBOARD_ROUTES.OVERVIEW,
        history: DASHBOARD_ROUTES.HISTORY,
        settings: DASHBOARD_ROUTES.SETTINGS,
        help: DASHBOARD_ROUTES.HELP,
      },
    };
  },
  created() {
    this.getUrlParams();
  },
  watch: {
    amount: function (newValue) {
      this.initialSearch = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "NGN",
      }).format(newValue || 0);
    },
    fullSearch: function (val) {
      if (val) {
        document.querySelector("body").classList.add("no-overflow");
      } else document.querySelector("body").classList.remove("no-overflow");
    },
  },
  mounted() {
    if (this.showModal) {
      document.querySelector("body").classList.add("no-overflow");
    } else document.querySelector("body").classList.remove("no-overflow");
  },
  methods: {
    search() {
      this.fullSearch = false;
      this.$emit("search");
    },
    getUrlParams() {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      for (let value of params.values()) {
        this.params.push(value);
      }
      this.paramsAmount = this.params[0];
      this.paramsIncome = this.params[1];
      this.paramsType = this.params[2];

      if (
        !Object.keys(this.$route.query).includes("amount") &&
        !Object.keys(this.$route.query).includes("type")
      ) {
        this.initialSearch = "Search for loans";
      } else {
        this.initialSearch = null;
        if (this.$route.query.amount && this.$route.query.type) {
          this.initialSearch = `₦ ${this.amount.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )} | ${this.$route.query.type}`;
        } else if (this.$route.query.amount)
          this.initialSearch = `₦ ${this.$route.query.amount.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )}`;
        else if (this.$route.query.type)
          this.initialSearch = `${this.$route.query.type}`;
      }
    },
    openMobileMenu() {
      this.slideIn = "animate-fast-slide";
      this.hideHamburger = "hidden";
    },
    closeMobileMenu() {
      this.slideIn = "hidden";
      this.hideHamburger = "";
    },
    showSearch() {
      this.fullSearch = true;
      this.$emit("showSearch", this.fullSearch);
      if (screen.width >= 1024) {
        this.$refs.fullSearchWrap.classList.add("bg-search-bg");
        this.$refs.fullSearchWrap.classList.add("h-screen");
        this.$refs.fullSearchWrap.classList.add("fixed");
      } else {
        // this.$refs.fullSearchWrap.style.marginTop = "110px";
        return false;
      }
    },
    closeSearch() {
      this.fullSearch = false;
      this.$emit("showSearch", this.fullSearch);
      if (screen.width >= 1024) {
        this.$refs.fullSearchWrap.classList.remove("fixed");
        this.$refs.fullSearchWrap.classList.remove("bg-search-bg");
        this.$refs.fullSearchWrap.classList.remove("h-screen");
      } else {
        this.$refs.fullSearchWrap.style.marginTop = "0";
      }
    },
    logoutUser() {
      localStorage.clear();
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.full-search-wrap {
  @media (min-width: 768px) {
    box-shadow: none;
  }
}

.full-search-wrap {
  @media (min-width: 1024px) {
    top: 96px;
    left: 0;
    right: 0;
  }
}

.search-form {
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, auto);
  }

  @media (min-width: 1440px) {
    grid-template-columns: 220px 215px 140px 228px 139px 150px;
  }
}

earch-hold-wrap-mobile {
  font-family: SohneKraftig, sans-serif;
  max-width: 343px;
  margin: auto;
}

.search-btn-small {
  width: 56px;
}

.auth-wrap, .title {
  font-family: SohneKraftig, sans-serif;
}

.borrow-amount-input,
button {
  font-family: SohneKraftig, sans-serif;
}
.auth-wrap > a {
  font-family: SohneKraftig, sans-serif;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
