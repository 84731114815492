<template>
  <div class="w-full">
    <div
      class="rounded-lg p-4 xxl:p-10 bg-white text-primary xxl:max-w-screen-xxl mx-auto"
      :class="selected ? 'border-2 border-primary' : 'border border-border'"
    >
      <div class="flex justify-between">
        <div class="flex items-start">
          <slot name="resultImage"></slot>
          <div class="ml-4 xxl:ml-6 flex flex-col md:justify-between justify-center">
            <h3 class="text-md md:text-xl result-title leading-tight">{{ resultTitle }}</h3>
            <span class="text-xs">{{ resultSubTitle }}</span>
          </div>
        </div>
        <span
          v-if="index === 0"
          class="bg-green-50 border-green-100 border md:text-xs text-xs p-1 md:p-2 text-success rounded result-tag h-fit-content"
          >{{ resultTag }}</span
        >
      </div>
      <div class="grid grid-cols-2 lg:grid-cols-5 mt-6 text-sm gap-6 result-container">
        <div class="">
          <h5 class="text-xs w-fit-content">Interest Rate</h5>
          <p class="detail-value mt-1">{{ interestValue }}</p>
        </div>
        <div class="">
          <h5 class="text-xs w-fit-content">Approval</h5>
          <p class="detail-value mt-1">{{ approvalValue }}</p>
        </div>
        <div class="">
          <h5 class="text-xs w-fit-content">Repayment Duration</h5>
          <p class="detail-value mt-1">{{ durationValue }}</p>
        </div>
        <div class="">
          <h5 class="text-xs w-fit-content">Rating</h5>
          <p class="detail-value mt-1">{{ ratingValue }}</p>
        </div>
        <div class="range-grid">
          <h5 class="text-xs w-fit-content">Range</h5>
          <p class="detail-value mt-1">{{ rangeValue }}</p>
        </div>
      </div>
      <div
        @click="showFullDetails()"
        class="text-center mt-8 text-sm full-details-btn lg:hidden cursor-pointer"
      >
        {{ fullDetailsBtn }}
      </div>
      <div class="grid lg:grid-cols-2 lg:gap-6 full-details">
        <div class="mt-10 lg:block" :class="fullDetails ? '' : 'hidden'">
          <p class="text-sm detail-title">Application Details</p>
          <div class="mt-3 grid detail-full gap-4 lg:gap-6">
            <div class="rounded p-4 bg-main-bg">
              <span class="compulsory-title mb-1">Compulsory</span>
              <slot name="compulsory"></slot>
            </div>
            <div class="rounded p-4 bg-main-bg">
              <span class="good-title mb-1">Good to have</span>
              <slot name="good"></slot>
            </div>
          </div>
        </div>
        <button
          :id="id"
          @click.prevent="(e) => selectResult(e, lenderData)"
          class="w-full border border-border h-14 mt-6 rounded-lg focus:outline-none select-btn lg:place-self-end"
          :class="selected ? 'bg-primary text-white' : 'bg-main-bg'"
        >
          {{ selected ? 'Unselect' : 'Select' }}
          </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoanResult",
  props: {
    id: [Function, String],
    resultTitle: String,
    resultSubTitle: String,
    resultTag: String,
    interestValue: [Function, String],
    approvalValue: String,
    durationValue: String,
    ratingValue: [String, Number],
    rangeValue: String,
    lenderData: Object,
    applicationDetails: Array,
    selected: Boolean,
    index: [String, Number],
  },
  data() {
    return {
      fullDetails: false,
      fullDetailsBtn: "Full Details",
      selectButton: "Select",
    };
  },
  methods: {
    selectResult(e, lenderData) {
      e.preventDefault();
      this.$emit('lenderselected', lenderData.id);
    },
    showFullDetails() {
      this.fullDetails = !this.fullDetails;
      if (this.fullDetails) {
        this.fullDetailsBtn = "Show Less";
      } else {
        this.fullDetailsBtn = "Full Details";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.result-title,
.result-tag,
.detail-value,
.full-details-btn,
.detail-title,
.compulsory-title,
.good-title,
.select-btn {
  font-family: SohneKraftig, sans-serif;
}
@media (min-width: 1024px) {
  .select-btn {
    width: 180px;
  }
  .detail-full {
    grid-template-columns: 360px 360px 1fr;
  }
}
@media (min-width: 1440px) {
  .result-container, .full-details {
    margin-left: 80px;
  }
}
.range-grid {
  grid-column: 1/3;
  @media (min-width: 1024px) {
    grid-column: 5/6;
  }
}
</style>